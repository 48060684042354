import queryString from 'query-string';
import { setCookie } from '../cookies';

const cookieNames = ['fbclid', 'gclid'];

const setAnalyticCookies = () => {
	const queryParams = queryString.parse(window.location.search);
	let parsedQuery = queryParams;

	if (queryParams?.r) {
		const splitRedirectUrl = /\?(.+)/.exec(queryParams.r);

		if (splitRedirectUrl?.length > 1) {
			parsedQuery = queryString.parse(splitRedirectUrl[1]);
		}
	}

	cookieNames.forEach((name) => {
		const value = parsedQuery[name];
		if (value) setCookie(name, value);
	});
};

export default setAnalyticCookies;
