/**
 * This middleware is responsible for sending experiment start
 * events to Mixpanel. Experiment events are built in Canary
 * and collected by Wisdom for test reporting.
 */

import { LOCATION_CHANGE } from 'connected-react-router';
import {
	START_EXPERIMENT,
	startExperimentAction,
} from 'stash/actions/experiments';

export function isRegexPath(url) {
	return url.indexOf('\\/') > -1;
}

export function getExperimentsStarted(experiments) {
	return experiments.reduce(
		(acc, exp) => ({
			...acc,
			[exp.version]: false,
		}),
		{}
	);
}

export function getExperimentsByPathname(experiments) {
	const expsByPathname = {
		paths: {},
		regex: {},
	};

	experiments.forEach(({ version, variant, assignment_urls }) => {
		assignment_urls.forEach((url) => {
			const urlType = isRegexPath(url) ? 'regex' : 'paths';
			expsByPathname[urlType][url] = [
				...(expsByPathname[urlType][url] || []),
				{ version, variant },
			];
		});
	});
	return expsByPathname;
}

const experiments = window.Stash.experiments || [];
const expsStarted = getExperimentsStarted(experiments);
const expsByPathname = getExperimentsByPathname(experiments);

export const getExpsForFixedPath = (expsByPaths, path) => {
	return expsByPaths[path] || [];
};

export const getExpsForRegexPath = (expsByRegex, path) => {
	return Object.keys(expsByRegex).reduce((acc, regexPath) => {
		const regex = new RegExp(regexPath);
		return regex.test(path) ? [...acc, ...expsByRegex[regexPath]] : acc;
	}, []);
};

export const startExp = (dispatch, { version, variant, user_id, uuid }) => {
	if (expsStarted[version] === false) {
		expsStarted[version] = true;
		dispatch(startExperimentAction(version, variant, false, user_id, uuid));
	}
};

export const startExperiments = (store, dispatch, exps) => {
	if (exps.length) {
		const { user_id, uuid } = store.entities.api_key;

		exps.forEach((exp) => {
			startExp(dispatch, { ...exp, user_id, uuid });
		});
	}
};

export default ({ dispatch, getState }) =>
	(next) =>
	(action) => {
		if (action.type === LOCATION_CHANGE && experiments.length) {
			const store = getState();
			const path = action.payload.location.pathname;

			startExperiments(
				store,
				dispatch,
				getExpsForFixedPath(expsByPathname.paths, path)
			);
			startExperiments(
				store,
				dispatch,
				getExpsForRegexPath(expsByPathname.regex, path)
			);
		}

		if (action.type === START_EXPERIMENT) {
			const body = {
				userId: String(action.user_id),
				testName: action.name,
				variant: action.variant,
				uuid: action.uuid,
			};

			fetch('/events/experiments', {
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			});
		}

		return next(action);
	};
