export const START_EXPERIMENT = 'START_EXPERIMENT';

export const startExperimentAction = (
	name,
	variant,
	forced,
	user_id,
	uuid
) => ({
	type: START_EXPERIMENT,
	name,
	user_id,
	uuid,
	variant,
	forced,
});
