import * as Sentry from '@sentry/browser';

// Block Edge 18 due to Sentry abuse from this UA
const isIgnoredUA = /Edge\/18/.test(window.navigator.userAgent);

function shouldSendEvent(event) {
	return isIgnoredUA;
}

function initSentry() {
	Sentry.init({
		dsn: 'https://7d24bcf367ed4bdebe9c1761dd63bea8@sentry.io/1514764',
		release: window.Stash.version,
		environment: window.Stash.env,
		beforeSend: (event) => {
			return shouldSendEvent(event) ? event : null;
		},
	});
}

export default initSentry;
