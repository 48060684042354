export const DEFAULT_ERROR_MESSAGE = 'Whoops, something went wrong. Please try again.';

/**
 * Returns a normalized error string from any of the stash error response
 * types.
 */
export const getErrorMessage = (error, fallbackMessage = DEFAULT_ERROR_MESSAGE) => {
	try {
		if (!!error?.displayError) {
			return error.displayError;
		}
		const errors = error && (error.errors || error.body);

		if (!errors) return fallbackMessage;

		if (Array.isArray(errors)) {
			return errors
				.map((error) => {
					return typeof error === 'object' ? error.message : error;
				})
				.join(' ');
		} else if (typeof errors === 'object') {
			return errors?.message ?? fallbackMessage;
		} else {
			return errors;
		}
	} catch {
		return fallbackMessage;
	}
};
