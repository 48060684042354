import { createSelector } from 'reselect';
import {
	PERSONAL_BROKERAGE,
	TRADITIONAL_IRA,
	ROTH_IRA,
} from 'stash/constants/accountTypes';
import { CLOSED_AASM_STATES } from 'stash/constants/mappings';

// Returns all accounts regardless of state
export const selectAllAccounts = (state) => {
	return state.entities.account || {};
};

// Returns only open accounts. Always use this unless you need to
// show closed accounts (ie. tax docs)
export const selectAccounts = createSelector([selectAllAccounts], (accounts) =>
	Object.values(accounts).reduce((acc, a) => {
		return CLOSED_AASM_STATES[a.state] ? acc : { ...acc, [a.id]: a };
	}, {})
);

// Returns all non-robo accounts for use in buy/sell flows
export const selectTransactionAccounts = createSelector([selectAccounts], (accounts) => {
	return Object.values(accounts).reduce((acc, account) => {
		if (!/ROBO/.test(account.type)) acc[account.id] = account;
		return acc;
	}, {});
});

export const selectAccountId = (state, accountType = 'PERSONAL_BROKERAGE') => {
	return state?.entities?.account_by_type[accountType];
};

export const selectAccount = createSelector(
	[selectAllAccounts, selectAccountId],
	(accounts, accountId) => accounts[accountId]
);

export const selectIsInvestAccountESig = (state) => {
	const investAccount = selectAccount(state, PERSONAL_BROKERAGE);
	return !!(investAccount && investAccount.e_signature_agreed_at);
};

export const selectIsRetireAccountESig = (state) => {
	const retireAccount =
		selectAccount(state, TRADITIONAL_IRA) || selectAccount(state, ROTH_IRA);
	return !!(retireAccount && retireAccount.e_signature_agreed_at);
};

export const selectDefaultAccountId = (state) => {
	return (
		selectAccountId(state, PERSONAL_BROKERAGE) ||
		selectAccountId(state, TRADITIONAL_IRA) ||
		selectAccountId(state, ROTH_IRA)
	);
};

export const selectMainAccount = createSelector(
	[selectAccounts, selectAccountId],
	(accounts, personalBrokerageId) =>
		accounts[personalBrokerageId] || Object.values(accounts)[0]
);
