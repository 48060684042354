import { getCookie } from 'stash/utils/cookies';

export const getInstrumentationProperties = () => {
	const fbclid = getCookie('fbclid');
	const gclid = getCookie('gclid');

	return {
		...(fbclid && { fbclid: `fb.1.${Date.now()}.${fbclid}` }),
		...(gclid && { gclid }),
		frontend_platform: 'web',
	};
};
