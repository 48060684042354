import { isProdOrStaging } from 'stash/utils';
import { getCookie } from 'stash/utils/cookies';
import { tryWarn } from '../utils';
import { UUID_COOKIE_NAME } from '../constants/session';
import { datadogRum } from '@datadog/browser-rum';

// This function expects events from 'product-events' and adds context necessary for forwarding to the
// clean segment workspace. For legacy events, use sendLegacySegmentTrackEvent.
export const track = tryWarn(({ event, properties, context, metric }) => {
	if (isProdOrStaging()) {
		datadogRum.addAction(metric, properties);
	}

	window.analytics?.track?.(event, properties, {
		context: {
			...context,
			stash_clean: true,
		},
	});
});

export const sendLegacySegmentTrackEvent = tryWarn((event, properties) => {
	window.analytics && window.analytics.track && window.analytics.track(event, properties);
});

export function sendMixpanel(event, properties) {
	if (!isProdOrStaging() && getCookie('stashEventLogger')) {
		console.info(`[${event}]`, { event, properties });
	}

	sendLegacySegmentTrackEvent(event, {
		...properties,
		...{
			deviceId: window?.analytics?.user && window?.analytics?.user().anonymousId(),
			uuid: getCookie(UUID_COOKIE_NAME),
		},
	});

	if (!window.dataLayer) {
		console.log('GTM dataLayer not found');
		return;
	}

	return window.dataLayer.push({
		event,
		properties: {
			Action: undefined,
			ScreenName: undefined,
			TileType: undefined,
			ActionLink: undefined,
			...properties,
		},
	});
}

export function sendUserLeapEvent(event, properties) {
	if (!window.UserLeap) {
		console.log('UserLeap SDK not found');
		return;
	}
	if (!isProdOrStaging() && getCookie('stashEventLogger')) {
		console.info(`[${event}]`, properties);
	}
	return window.UserLeap(event, properties);
}

export function trackUserLeapEvent(eventName) {
	return sendUserLeapEvent('track', eventName);
}

export function setUserLeapAttribute(attribute, value) {
	return sendUserLeapEvent('setAttributes', {
		[attribute]: value,
	});
}
