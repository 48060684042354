export const DISCLOSURES_URL = 'https://www.stash.com/disclosurelibrary';

export const GREEN_DOT_PRIVACY_POLICY_URL =
	'https://cdn.stash.com/disclosures/Green+Dot+Bank+Privacy+Policy.pdf';

export const ELECTRONIC_COMMUNICATIONS_AGREEMENT_URL =
	'https://cdn.stash.com/disclosures/Green+Dot+Bank+Electronic+Communications+Agreement.pdf';

export const DEPOSIT_AMOUNT_AGREEMENT_URL =
	'https://cdn.stash.com/disclosures/greenDotBankDepositAccountAgreement202003.pdf';

export const PLAY_STORE_DISCLOSURE_TEXT =
	'Google Play and the Google Play logo are trademarks of Google, Inc.';

export const APP_STORE_DISCLOSURE_TEXT =
	'Apple, the Apple logo, and iPhone are trademarks of Apple, Inc., registered in the U.S. and other countries. App Store is a service mark of Apple, Inc.';

export const BANKING_FEE_OUTLINE_URL =
	'https://cdn.stash.com/messaging-assets/banking/Stash-Banking-Fee-Outline-V2.pdf';

export const STRIDE_STOCK_BACK_REWARDS_DISCLOSURES =
	'https://lp.stash.com/stride-stash-stock-back-rewards-terms-and-conditions/';

export const STRIDE_DEPOSIT_ACCOUNT_AGREEMENT =
	'https://cdn.stash.com/disclosures/stride-deposit-account-agreement.pdf';

export const STRIDE_BANK_PRIVACY_POLICY =
	'https://cdn.stash.com/disclosures/stride-privacy-policy.pdf';

export const STASH_ESIGN_AGREEMENT =
	'https://cdn.stash.com/disclosures/stash-e-sign-agreement.pdf';
