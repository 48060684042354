export const TRANSFER_FREQUENCY = {
	oneTime: {
		frequency: 'ONCE',
		display: 'once',
		quantified: 1,
	},
	weekly: {
		frequency: 'WEEKLY',
		display: 'weekly',
		quantified: 52,
	},
	biWeekly: {
		frequency: 'BI-WEEKLY',
		display: 'biweekly',
		quantified: 26,
	},
	monthly: {
		frequency: 'MONTHLY',
		display: 'monthly',
		quantified: 12,
	},
};

// TODO: DEPRECATE and clean up autostash mappings.
export const AUTO_STASH_FREQUENCY_TEXT = {
	WEEKLY: 'every week',
	'BI-WEEKLY': 'every two weeks',
	MONTHLY: 'every month',
};

export const PITHY_AUTO_STASH_FREQUENCY_TEXT = {
	ONCE: '',
	WEEKLY: 'weekly',
	'BI-WEEKLY': 'biweekly',
	MONTHLY: 'monthly',
};

// USE THIS MAPPING INSTEAD OF 'AUTO_STASH_FREQUENCY_TEXT'.
export const AUTO_STASH_FREQUENCY_MAP = {
	WEEKLY: {
		lowercase: 'every week',
		titlecase: 'Every week',
		shortTitle: 'Weekly',
	},
	'BI-WEEKLY': {
		lowercase: 'every two weeks',
		titlecase: 'Every two weeks',
		shortTitle: 'Biweekly',
	},
	MONTHLY: {
		lowercase: 'every month',
		titlecase: 'Every month',
		shortTitle: 'Monthly',
	},
	NONE: {
		lowercase: 'just once',
		titlecase: 'Just once',
		shortTitle: 'Once',
	},
};

export const AUTO_STASH_FREQUENCY_OPTIONS = [
	{
		value: 'WEEKLY',
		title: 'Every week',
		short_title: 'every week',
	},
	{
		value: 'BI-WEEKLY',
		title: 'Every two weeks',
		short_title: 'every two weeks',
	},
	{
		value: 'MONTHLY',
		title: 'Every month',
		short_title: 'every month',
	},
];

export const PITHY_AUTO_STASH_FREQUENCY_OPTIONS = [
	{
		value: 'WEEKLY',
		title: 'Weekly',
		short_title: 'every week',
	},
	{
		value: 'BI-WEEKLY',
		title: 'Biweekly',
		short_title: 'every two weeks',
	},
	{
		value: 'MONTHLY',
		title: 'Monthly',
		short_title: 'every month',
	},
];

// 'NONE' is not an actual value for the backend.
export const ALL_AUTO_STASH_FREQUENCY_OPTIONS = [
	...AUTO_STASH_FREQUENCY_OPTIONS,
	{
		value: 'NONE',
		title: 'Just once',
		short_title: 'just once',
	},
];

export const RISK_LEVEL_NAME = {
	1: 'Conservative',
	2: 'Moderate',
	3: 'Aggressive',
	4: 'Secret',
};

export const RISK_LEVELS = {
	conservative: 1,
	moderate: 2,
	aggressive: 3,
	secret: 4,
};

export const ACCOUNT_STATES = {
	complete: 'complete',
	queued_for_apex: 'queued_for_apex',
	pending: 'pending',
	back_office: 'back_office',
	in_closing_process: 'in_closing_process',
	in_activation_process: 'in_activation_process',
	in_reopen_process: 'in_reopen_process',
	closed: 'closed',
	indeterminate: 'indeterminate',
	rejected: 'rejected',
	impeded: 'impeded',
	incomplete: 'incomplete',
	inactive: 'inactive',
	errored: 'errored',
	unsupported: 'unsupported',
};

export const VALID_AASM_STATES = {
	complete: true,
	queued_for_apex: true,
	pending: true,
	back_office: true,
	in_closing_process: true,
	in_activation_process: true,
};

export const CLOSED_AASM_STATES = {
	closed: true,
};

export const REJECTED_AASM_STATES = {
	rejected: true,
	impeded: true,
	indeterminate: true,
	closed: true,
	errored: true,
};

export const ARCHIVE_RESTRICTED_REASONS = {
	ACCOUNT_VERIFICATION_CALL_IN: true,
	CLOSED: true,
	DEFAULT: true,
	IN_CLOSING_PROCESS: true,
	IN_CLOSING_PROCESS_OUTDATED_USER_AGENT: true,
	OFAC: true,
	OFAC_COUNTRY: true,
};

export const LEMONADE_QUALIFIED_STATES = {
	AZ: true,
	CA: true,
	DC: true,
	GA: true,
	IL: true,
	IO: true,
	MD: true,
	MI: true,
	NV: true,
	NJ: true,
	NM: true,
	NY: true,
	OH: true,
	PA: true,
	RI: true,
	TX: true,
	WI: true,
};

export const DEBIT_QUALIFIED_AASM_STATES = {
	complete: true,
	back_office: true,
	pending: true,
	indeterminate: true,
	rejected: true,
};

export const RISK_LEVEL_CARDS = {
	1: {
		featuredCard: 'AOK',
		heading: 'Conservative risk investments for you',
		cards: ['SPLV', 'VPU', 'SCHD', 'MINT'],
	},
	2: {
		featuredCard: 'AOM',
		heading: 'Moderate risk investments for you',
		cards: ['MGC', 'VT', 'SCHD', 'VGT'],
	},
	3: {
		featuredCard: 'AOR',
		heading: 'Aggressive risk investments for you',
		cards: ['VT', 'VB', 'BRK.B', 'VWO'],
	},
	4: {
		featuredCard: 'AOM',
		heading: 'Investments for your risk level',
		cards: ['SPLV', 'VPU', 'SCHD', 'MINT'],
	},
};

export const RISK_VOLATILITIES = {
	min: 'MIN',
	median: 'MEDIAN',
	max: 'MAX',
};

export const REACTIVATION_CONSTANTS = {
	REACTIVATION: 'reactivation',
	REOPEN: 'reopen',
};
