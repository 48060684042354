export default [
	['/', 'Home'],
	['/ira-withdrawal', 'IraWithdrawal'],
	['/retire', 'Retire'],
	['/cards/search', 'InvestmentSearch'],
	['/cards/:cardUuid', 'InvestmentDetailContainer'],
	['/cards/:cardUuid/stockBack', 'SetStockBackModal'],
	['/cards/:cardUuid/:viewType', 'InvestmentDetailContainerModal'],
	['/card-transactions', 'CardTransactionsContainer'],
	['/investments/search', 'InvestmentSearch'],
	['/investments', 'InvestmentsContainer'],
	['/browse-collection', 'BrowseRedirect'],
	['/browse-collection/:section', 'BrowseContainer'],
	['/bookmarks', 'BookmarksContainer'],
	['/browse-collection/:detailType/:detailId', 'CollectionDetailContainer'],
	['/portfolio', 'PortfolioContainer'],
	['/portfolio/investments', 'PortfolioInvestments'],
	['/portfolio/diversification-score', 'DiversificationScore'],
	['/portfolio/diversification-score/:screen', 'DiversificationScore'],
	['/verify/:token', 'Verify'],
	['/sell/new', 'SellContainerModal'],
	['/purchases/new', 'BuyContainerModal'],
	['/purchases/first', 'BuyContainerModal'],
	['/purchase-verification', 'BuyVerificationModal'],
	['/withdrawals/new', 'WithdrawContainerModal'],
	['/bank-accounts/new', 'LinkBankContainerModal'],
	['/bank-accounts/new/:linkType', 'LinkBankContainerModal'],
	['/feedback-form', 'FeedbackFormModal'],
	['/referrals/friends', 'ReferralFriends'],
	['/referrals', 'Referrals'],
	['/referrals/qr-code', 'QrCode'],
	['/debit-download', 'DebitSignUpSuccess'],
	['/smart-invest-sign-up/:screen?', 'ManagedAccountsSignUp'],
	['/auto-save/set-schedule/reconfirm', 'SetScheduleReconfirmationContainer'],
	['/custodial-sign-up/:screen?', 'CustodialSignUp'],
	['/tips/:slug', 'TipDetailModal'],
	['/oauth/plaid/link', 'LinkPlaidOAuthModal'],
	['/payment/add-card', 'AddPaymentCardContainer'],
	['/update-income', 'UpdateUserIncome'],
	['/my-stash', 'MyStashPage'],
	['/change-payment-method', 'ChangePaymentMethodContainer'],
	['/onboarding/purchase', 'OnboardingPurchaseModal'],
];
