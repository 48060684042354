import { AUTH_SESSION_START, LOG_OUT } from '../actions';
import { tryWarn } from '../utils';

const identifyAnonymousUser = tryWarn((uuid) => {
	if (uuid && window.analytics?.identify && window.analytics?.track) {
		// identify with old workspace
		window.analytics?.identify(uuid);

		// identify with new workspace
		window.analytics?.identify(
			uuid,
			{},
			{
				context: {
					stash_clean: true,
				},
			}
		);
	}
});

const middleware = () => (next) => (action) => {
	switch (action.type) {
		case AUTH_SESSION_START:
			identifyAnonymousUser(action.uuid);
			break;
		case LOG_OUT:
			window.analytics?.reset();
			break;
		default:
			break;
	}

	return next(action);
};
export default middleware;
