import { captureException } from '@sentry/browser';

export const DATE_OF_BIRTH = 'date_of_birth';

export const usdCurrency = (value, cents = true, showPlusSign = false) => {
	const parsed = ((value) => {
		const amount =
			typeof value === 'string' ? parseFloat(value.replace(/[^0-9.-]/g, '')) : value;
		const prefix = amount < 0 ? '-$' : showPlusSign ? '+$' : '$';

		return (
			prefix +
			Math.abs(amount)
				.toFixed(2)
				.replace(/./g, (c, i, a) => {
					return i && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
				})
		);
	})(value);
	return cents ? parsed : parsed.substr(0, parsed.length - 3);
};

export const usdCurrencyNoZero = (value) => {
	return usdCurrency(value).replace('.00', '');
};

export const stringToNumberConverter = (value) => {
	const numberString = value.replace(/[^0-9.-]/g, '');
	return parseFloat(numberString);
};

export const formatInputCurrency = (value) => {
	// remove all non-numerical values except for . and -
	const numberString = value.replace(/[^0-9.-]/g, '');
	const num = parseFloat(numberString, 10);

	// if empty return initial state of $
	if (!num) return '$';

	// we need to keep the trailing decimal for typing purposes
	const decimalPlace = numberString.split('.')[1];
	const suffix = decimalPlace !== undefined && !decimalPlace.length ? '.' : '';

	// round to 2 decimal places only if needed
	const roundedNum = Math.round(num * 100) / 100;

	// return currency formatted
	return '$' + roundedNum.toLocaleString('en-US') + suffix;
};

export const getDOBFromString = (dob) => {
	if (typeof dob !== 'string') {
		throw new Error('A non-string argument was passed to getDOBFromString Function');
	}

	const parts = dob.replace(/\s+/g, '').split('-');
	const birth_year = parts[0];
	const birth_month = parts[1];
	const birth_day = parts[2];

	return {
		birth_month,
		birth_day,
		birth_year,
	};
};

export const formatDOB = (year, month, day) => {
	if (typeof year !== 'string' || typeof month !== 'string' || typeof day !== 'string') {
		throw new Error('A non-string argument was passed to formatDOB Function');
	}

	return `${year}-${month}-${day}`;
};

export const nl2br = (string) => string.replace(/\n/g, '<br />');

export const trimFirstPhoneDigit = (number) => {
	if (!number) {
		captureException(new Error('TrimFirstDigit called without argument'));
		return '';
	}
	return number.slice(1);
};

export const formatSpaceAfterCertainNumber = (charactersToSpace) => {
	if (typeof charactersToSpace !== 'number') throw new Error('Please enter a number');

	return function (string) {
		if (typeof string !== 'string') throw new Error('Please enter a string');
		const regex = new RegExp(`\\d{${charactersToSpace}}`, 'g');

		return string.replace(regex, (match) => match + ' ');
	};
};

export const format4Spaces = formatSpaceAfterCertainNumber(4);
export const format3Spaces = formatSpaceAfterCertainNumber(3);

export function getReadableValue(number, hideCurrency) {
	if (typeof number !== 'number') return 'N/A';
	number = Math.abs(Number(number));
	const prefix = hideCurrency ? '' : '$';
	const trillions = number / 1.0e12;
	const billions = number / 1.0e9;
	const millions = number / 1.0e6;
	const thousands = number / 1.0e3;

	return number >= 1.0e12
		? prefix + trillions.toFixed(1) + ' trillion'
		: number >= 1.0e9
		? prefix + billions.toFixed(1) + ' billion'
		: number >= 1.0e6
		? prefix + millions.toFixed(1) + ' million'
		: number >= 1.0e3
		? prefix + thousands.toFixed(1) + ' thousand'
		: prefix + number;
}

export const percentReturn = (value, decimalPlaces = 2) => {
	return `${value >= 0 ? '+' : '-'}${Math.abs(value).toFixed(decimalPlaces)}%`;
};

export const capitalizeFirstLetter = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatCurrencyWithSeparatedDecimals = (value) => {
	if (isNaN(value)) return { whole: '$-', decimal: '.--' };

	const isNegative = value < 0;
	const absoluteValue = Math.abs(value);
	const [wholePart, decimalPart] = absoluteValue.toFixed(2).split('.');
	const formattedWholePart = parseInt(wholePart, 10).toLocaleString();

	return {
		whole: `${isNegative ? '-$' : '$'}${formattedWholePart}`,
		decimal: `.${decimalPart}`,
	};
};
