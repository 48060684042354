import { sendMixpanel } from './utils';

export function trackTransferCta() {
	sendMixpanel('CheckingHome', {
		ScreenName: '',
		Action: 'CheckingHomeUniversalTransfer',
	});
}

export function trackDDCta() {
	sendMixpanel('CheckingHome', {
		ScreenName: '',
		Action: 'CheckingHomeDirectDeposit',
	});
}

export function trackPartitionsCta() {
	sendMixpanel('CheckingHome', {
		ScreenName: '',
		Action: 'CheckingHomeQuickActionOrganizeCTA',
	});
}
export function recurringTransferCta() {
	sendMixpanel('CheckingHome', {
		ScreenName: '',
		Action: 'CheckingHomeRecurringTransfers',
	});
}

export function stockBackTrackerCta() {
	sendMixpanel('CheckingHome', {
		ScreenName: '',
		Action: 'CheckingHomeStockBack',
	});
}

export function accountManagementLink() {
	sendMixpanel('CheckingHome', {
		ScreenName: 'CheckingHome',
		Action: 'CheckingHomeAccountManagement',
	});
}
