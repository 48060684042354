import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Flex, Text } from '@stashinvest/ui';
import { accountManagementLink } from '../../events/banking';

// [Mar 15 2020] This is a temporary stop-gap solution for a subnavigation for bank
// until we implement the new revamped navigation system in ~3 months

const SecondaryNavContainer = styled(Flex)`
	position: fixed;
	left: 1rem;
	right: 0;
	top: 4rem;
	z-index: 11;
	height: 2.375rem;

	align-items: center;

	${({ theme }) => theme.mediaQueries.tablet} {
		position: absolute;
		left: 0;
	}

	&:before {
		content: '';
		position: fixed;
		z-index: -1;
		left: 0;
		right: 0;
		height: 2.375rem;
		background: ${(p) => p.theme.colors.bgActionSecondary};
		pointer-events: none;
	}
`;

const NavItem = styled(Text.BodySmall).attrs({
	color: 'textPrimary',
	activeStyle: {
		opacity: 1,
	},
})`
	text-decoration: none;
	opacity: 0.44;
`;

export function SecondaryBankNav() {
	return (
		<SecondaryNavContainer>
			<NavItem pr="l" to="/debit" exact forwardedAs={NavLink}>
				Overview
			</NavItem>
			<NavItem
				to="/debit/cardManagement"
				exact
				forwardedAs={NavLink}
				onClick={accountManagementLink}
			>
				Account management
			</NavItem>
		</SecondaryNavContainer>
	);
}

export default SecondaryBankNav;
