import React, { lazy, Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import LazyLoadComponent from 'stash/components/global/LazyLoadComponent';
import { LoaderContainer } from 'stash/components/global/LazyLoadComponent/LoaderContainer';
import App from 'stash/components/app/App';
import { unavailablePaths } from './Unavailable';
import redirects from './Redirects';
import AccountAdminPaths from './AccountAdminRoutes/paths';
import SessionManagementPaths from './SessionManagementRoutes/paths';
import SettingsPaths from './SettingsRoutes/paths';
import DashboardPaths from './DashboardRoutes/paths';
import DashboardContainer from 'stash/components/dashboard/DashboardContainer';
import Dashboard from 'stash/components/dashboard/Dashboard';
import { history } from 'stash/store';
import { isProd } from 'stash/utils';
import { DisabledFeaturesList } from './BankRoute';
import RouterHelmet from './RouterHelmet';
import { Loader } from '@stashinvest/ui';

const Settings = lazy(() => import('../components/settings/Settings'));
const BankRoute = lazy(() => import('./BankRoute'));
const Unavailable = lazy(() => import('stash/components/unavailable/Unavailable'));
const DevTools = lazy(() => import('stash/components/devtools/DevTools'));
const Dialog = lazy(() => import('stash/components/dialog/Dialog'));
const PageNotFound404 = lazy(() => import('stash/components/404-page/PageNotFound404'));

// https://github.com/pillarjs/path-to-regexp/issues/145
const replaceOptionalParamWithWildcard = (path) => {
	return path
		.split('/')
		.map((segment) => segment.replace(/^:.*$/, '.*'))
		.join('/');
};

const buildRouteMatchRegex = (routes) =>
	`(${routes.map(replaceOptionalParamWithWildcard).join('|')})`;

const AsyncAccountAdminRouter = LazyLoadComponent(() =>
	import('./AccountAdminRoutes' /* webpackChunkName: "AccountAdminRouter" */)
);

const AsyncArchiveRouter = LazyLoadComponent(() =>
	import('./ArchiveRoutes' /* webpackChunkName: "ArchiveRouter" */)
);

const AsyncSessionManagementRouter = LazyLoadComponent(
	() =>
		import('./SessionManagementRoutes' /* webpackChunkName: "SessionManagementRouter" */),
	{ showNav: true }
);

const AsyncSignUpRouter = LazyLoadComponent(
	() => import('./SignUpRoutes' /* webpackChunkName: "SignUpRouter" */),
	{ showNav: true }
);

const AsyncDevRouter = LazyLoadComponent(() =>
	import('./DevRoutes' /* webpackChunkName: "DevRouter" */)
);

const AsyncAppetizerRouter = React.lazy(() =>
	import('./AppetizerRoutes' /* webpackChunkName: "AppetizerRouter" */)
);

const AsyncAutoStashRouter = LazyLoadComponent(() =>
	import('./AutoStashRoutes' /* webpackChunkName: "AutoStashRouter" */)
);

const AsyncSolutionsRouter = LazyLoadComponent(() =>
	import('./SolutionsRoutes' /* webpackChunkName: "SolutionsRouter" */)
);
const AsyncGoalsRouter = LazyLoadComponent(() =>
	import('../components/goals' /* webpackChunkName: "GoalsRouter" */)
);
const AsyncSettingsRouter = LazyLoadComponent(() =>
	import('./SettingsRoutes' /* webpackChunkName: "SettingsRouter" */)
);

const AsyncInsuranceRouter = LazyLoadComponent(() =>
	import('./InsuranceRoutes' /* webpackChunkName: "InsuranceRouter" */)
);

const AsyncDebitRouter = LazyLoadComponent(() =>
	import('./DebitRoutes' /* webpackChunkName: "DebitRouter" */)
);

const AsyncNorthStarUniversalTransferRouter = LazyLoadComponent(
	() =>
		import(
			'../components/north-star-universal-transfer'
		) /* webpackChunkName: "ns-UniversalTransferRouter" */
);
const AsyncDashboardRouter = LazyLoadComponent(() =>
	import('./DashboardRoutes' /* webpackChunkName: "DashboardRouter" */)
);

const BankStatements = React.lazy(() =>
	import(
		'../components/bank-statements/BankStatements' /* webpackChunkName: "BankStatements" */
	)
);

const AsyncContentContainerRouter = LazyLoadComponent(() =>
	import('./ContentContainerRouter' /* webpackChunkName: "ContentContainerRouter" */)
);

const AsyncReactivationRouter = LazyLoadComponent(() =>
	import('./ReactivationRoutes' /* webpackChunkName: "ReactivationRouter" */)
);

const AsyncStockRewardRouter = LazyLoadComponent(() =>
	import('./StockRewardRoutes' /* webpackChunkName: "StockRewardRouter" */)
);

const AsyncGuideContainerRouter = LazyLoadComponent(() =>
	import('./GuideContainerRouter' /* webpackChunkName: "GuideContainerRouter" */)
);

const AsyncDeeplinkHandler = LazyLoadComponent(() =>
	import(
		'stash/components/deeplinks/DeeplinkHandler' /* webpackChunkName: "GuideContainerRouter" */
	)
);

// -----------------------------------------------------------------------------
// Route configuration
// -----------------------------------------------------------------------------

// These routes each include their own container for loading the data they need
// and have sub-paths
const baseRoutes = [
	{ path: '/subscriber-advice*', router: AsyncContentContainerRouter },
	{ path: '/sign-up-*', router: AsyncSignUpRouter },
	{ path: '/reactivation*', router: AsyncReactivationRouter },
	{ path: '/archive', router: AsyncArchiveRouter },
	{ path: '/dev', router: AsyncDevRouter },
	{ path: '/onboarding/guides/welcome', router: AsyncGuideContainerRouter },
];

// Top level routes that match on path for lazy-loading
const basePathRoutes = [
	{ paths: AccountAdminPaths, router: AsyncAccountAdminRouter },
	{ paths: SessionManagementPaths, router: AsyncSessionManagementRouter },
];

// Routes within the main dashboard container that have nested sub-paths
const dashboardRoutes = [
	{ path: '/auto-stash', router: AsyncAutoStashRouter },
	{ path: '/appetizer', router: AsyncAppetizerRouter },
	{ path: '/solutions', router: AsyncInsuranceRouter },
	{ path: '/debit', router: AsyncDebitRouter },
	{ path: '/goals', router: AsyncGoalsRouter },
	{ path: '/solutions', router: AsyncSolutionsRouter },
	{ path: '/stock-reward', router: AsyncStockRewardRouter },
	{ path: '/transfer', router: AsyncNorthStarUniversalTransferRouter },
	{ path: '/manage-subscription/link-payment-method', router: AsyncDeeplinkHandler },
];

// Dashboard routes that match on path for lazy-loading
const dashboardPathRoutes = [
	{ paths: SettingsPaths, router: AsyncSettingsRouter },
	{ paths: DashboardPaths, router: AsyncDashboardRouter },
];

const IndexRouter = () => {
	return (
		<ConnectedRouter history={history}>
			<Suspense
				fallback={
					<LoaderContainer>
						<Loader center />
					</LoaderContainer>
				}
			>
				<App>
					{!isProd() && <DevTools />}
					<RouterHelmet />
					<Switch>
						<Redirect from="/web/*" to="/*" />
						{redirects.map(({ from, to }) => (
							<Route
								exact
								path={from}
								key={`${from}-${to}`}
								render={() => <Redirect to={to} />}
							/>
						))}

						{/* Custom redirect needed to preserve query params  */}
						<Route
							exact
							path={['/set-schedule', '/set-schedule/setup']}
							render={({ location }) => (
								<Redirect
									to={{
										...location,
										pathname: '/auto-stash' + location.pathname,
									}}
								/>
							)}
						/>

						{baseRoutes.map(({ path, router }) => (
							<Route path={path} key={path} component={router} />
						))}
						{basePathRoutes.map(({ paths, router }) => {
							const pathRegex = buildRouteMatchRegex(paths.map(([path]) => path));
							return <Route exact key={pathRegex} path={pathRegex} component={router} />;
						})}
						{/* Match all dashboard specific routes */}
						<Route
							path={buildRouteMatchRegex(
								DashboardPaths.concat(SettingsPaths)
									.map(([path]) => path)
									.concat(dashboardRoutes.map((p) => p.path))
							)}
							render={(props) => {
								return (
									<DashboardContainer {...props}>
										<Dashboard {...props}>
											{dashboardRoutes.map(({ path, router }) => (
												<Route path={path} key={path} component={router} />
											))}

											{/*
											wrapping BankRoute in a regular route to
											prevent it from calling /accounts endpoint before its needed
										*/}
											<Route exact path="/documents/bankStatements">
												<BankRoute
													feature={DisabledFeaturesList.Statements}
													redirect="/debit"
												>
													<Settings>
														<BankStatements />
													</Settings>
												</BankRoute>
											</Route>

											{dashboardPathRoutes.map(({ paths, router }) => {
												const pathRegex = buildRouteMatchRegex(
													paths.map(([path]) => path)
												);
												return (
													<Route
														exact
														key={pathRegex}
														path={pathRegex}
														component={router}
													/>
												);
											})}
										</Dashboard>
									</DashboardContainer>
								);
							}}
						/>
						{unavailablePaths.map((path) => (
							<Route path={path} component={Unavailable} key={path} />
						))}
						<Route path="*" component={PageNotFound404} />
					</Switch>
					<Dialog />
				</App>
			</Suspense>
		</ConnectedRouter>
	);
};

export default IndexRouter;
