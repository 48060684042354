/**
 * This file composes middleware before creating the store.
 * Middleware provides a third-party extension point between
 * dispatching an action, and the moment it reaches the reducer.
 *
 * Read more about middleware here:
 * http://redux.js.org/docs/advanced/Middleware.html
 *
 */

import { applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import promo from './promo';
import braze from './braze';
import nudata from './nudata';
import userleap from './userleap';
import { routerMiddleware } from 'connected-react-router';
import session from './session';
import events from './events';
import experiments from './experiments';
import thunk from './thunk';
import fetchMiddleware from './fetch';
import dialog from './dialog';
import recaptcha from './recaptcha';
import segment from './segment';
import datadogRumMiddleware from './datadog';

const createMiddleware = (history) => {
	const middleware = [
		routerMiddleware(history),
		promo,
		recaptcha,
		session,
		events,
		dialog,
		braze,
		nudata,
		userleap,
		fetchMiddleware,
		thunk,
		experiments,
		segment,
		datadogRumMiddleware,
	];
	return composeWithDevTools(applyMiddleware(...middleware));
};

export default createMiddleware;
