import React, { useEffect } from 'react';
import { Avatar, Banner } from '@stashinvest/react-components';
import { KeyIcon } from '@stashinvest/react-assets';
import { BanjoString } from '@stashinvest/react-banjo';
import { STASH_WORKS_WEB_PATH } from 'stash/constants';
import { useStashWorksStatus } from 'stash/hooks/stashWorks';
import { trackSettingsView, trackStashWorksBannerClick } from 'stash/events/settings';
import { track } from 'stash/events/utils';
import {
	v1_UserProfile_Viewed,
	v1_StashWorks_Clicked,
} from '@stashinvest/product-events-javascript';

const StashWorksBanner = () => {
	const { hasStashWorks, isLoading } = useStashWorksStatus();

	const handleBannerClick = () => {
		// Legacy GTM tracking
		trackStashWorksBannerClick();
		// New segment tracking
		track(v1_StashWorks_Clicked({ origin: 'user_profile' }));
		window.location.href = STASH_WORKS_WEB_PATH;
	};

	useEffect(() => {
		if (!isLoading) {
			// Legacy GTM tracking
			trackSettingsView(hasStashWorks);
			// New segment tracking
			track(v1_UserProfile_Viewed({ stashworks_banner: hasStashWorks ? 'yes' : 'no' }));
		}
	}, [hasStashWorks, isLoading]);

	return isLoading ? (
		<></>
	) : (
		hasStashWorks && (
			<div className="w-full gridMedium:w-3/4 mx-auto">
				<Banner
					onClick={handleBannerClick}
					variant={{
						avatar: (
							<Avatar
								backgroundType="fill"
								shape="square"
								variant={{
									Icon: KeyIcon,
									size: 'small',
									type: 'icon',
									variant: 'neutral',
								}}
							/>
						),
						text: <BanjoString stringId="action/startTask/goToStashWorks" />,
						type: 'default',
					}}
				/>
			</div>
		)
	);
};

export default StashWorksBanner;
