import { createStore as reduxCreateStore } from 'redux';
import createRootReducer from 'stash/reducers';
import { createBrowserHistory } from 'history';
import createMiddleware from 'stash/middleware';
import { init } from 'stash/actions/init';
import hydratedState from './hydrate';

export const history = createBrowserHistory();

export const createStore = (initialState) => {
	return reduxCreateStore(
		createRootReducer(history),
		initialState,
		createMiddleware(history)
	);
};

const store = createStore(hydratedState);

store.dispatch(init());

export default store;
