import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Text, InvestIcon, PlanIcon, SpendIcon, TransferIcon } from '@stashinvest/ui';

import { navTransferTab } from './events';
import { URL_MATCHES } from './TopBar';
import { ExperimentVariant } from '../ab-testing';
import { Rail } from '@stashinvest/react-components';

const BottomNav = styled.nav`
	position: fixed;
	z-index: 11;
	bottom: 0;
	background: ${(p) => p.theme.colors.bgPrimary};
	width: 100%;
	height: 62px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

	${({ theme }) => theme.mediaQueries.tablet} {
		display: none;
	}
`;

const RailBottomNav = styled.nav`
	position: fixed;
	z-index: 11;
	bottom: 0;
	background: ${(p) => p.theme.colors.bgPrimary};
	width: 100%;
	height: 62px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
`;

const NavUl = styled.ul`
	display: flex;
	z-index: 1;
	justify-content: space-around;
	padding-top: ${({ theme }) => theme.space.xxs};
	padding-bottom: ${({ theme }) => theme.space.xxs};
	height: 100%;
`;

const NavLi = styled.li`
	display: inline;
	color: ${(p) => p.theme.colors.textSecondary};
`;

const BottomNavLink = styled(NavLink)`
	text-decoration: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&.active {
		color: ${(p) => p.theme.colors.iconSelected};
	}
`;

export const MobileNav = (props) => {
	return (
		<>
			<ExperimentVariant experiment="isSdsRailEnabled" variant={false} defaultVariant>
				<BottomNav>
					<NavUl>
						<NavLi>
							<BottomNavLink
								to="/invest"
								isActive={(match, location) =>
									URL_MATCHES['invest'].includes(location.pathname)
								}
							>
								<InvestIcon color="currentColor" size="1.5rem" />
								<Text.BodySmall color="currentColor">Investing</Text.BodySmall>
							</BottomNavLink>
						</NavLi>
						<NavLi>
							<BottomNavLink to="/debit">
								<SpendIcon color="currentColor" size="1.5rem" />
								<Text.BodySmall color="currentColor">Bank</Text.BodySmall>
							</BottomNavLink>
						</NavLi>
						<NavLi>
							<BottomNavLink to="/solutions">
								<PlanIcon color="currentColor" size="1.5rem" />
								<Text.BodySmall color="currentColor">Plan</Text.BodySmall>
							</BottomNavLink>
						</NavLi>
						<NavLi>
							<BottomNavLink to="/transfer/sources" onClick={navTransferTab}>
								<TransferIcon color="currentColor" size="1.5rem" />
								<Text.BodySmall color="currentColor">Transfer</Text.BodySmall>
							</BottomNavLink>
						</NavLi>
					</NavUl>
				</BottomNav>
			</ExperimentVariant>
			<ExperimentVariant experiment="isSdsRailEnabled" variant={true}>
				<RailBottomNav className="block gridLarge:hidden">
					<Rail
						selectedIndex={props?.selectedIndex}
						onIndexSelect={(index) => {
							props?.onNavLinkClick(index);
						}}
						navItems={props?.navOptions}
					/>
				</RailBottomNav>
			</ExperimentVariant>
		</>
	);
};

MobileNav.propTypes = {
	navOptions: PropTypes.array,
	selectedIndex: PropTypes.number,
	onNavLinkClick: PropTypes.func,
};

export default MobileNav;
