/**
 * We 'hydrate' persisted state from cookies/localStorage into
 * the store. This is passed into initialState when the store
 * is first instantiated.
 *
 */

import { getCookie } from '../utils/cookies';
import {
	ACCESS_TOKEN_COOKIE_NAME,
	REFRESH_TOKEN_COOKIE_NAME,
	USER_ID_COOKIE_NAME,
	UUID_COOKIE_NAME,
} from '../constants/session';

export default {
	entities: {
		api_key: {
			user_id: getCookie(USER_ID_COOKIE_NAME),
			uuid: getCookie(UUID_COOKIE_NAME),
			access_token: getCookie(ACCESS_TOKEN_COOKIE_NAME),
			refresh_token: getCookie(REFRESH_TOKEN_COOKIE_NAME),
		},
	},
};
