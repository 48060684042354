import { datadogRum } from '@datadog/browser-rum';
import { LOG_OUT, STASH_INIT } from 'stash/actions';
import { AUTH_SESSION_START } from 'stash/actions';
import { isProdOrStaging } from 'stash/utils';

const middleware = () => (next) => (action) => {
	if (isProdOrStaging()) {
		switch (action.type) {
			case STASH_INIT:
				const config = {
					clientToken: 'pubc692d28ef23ddfa4d219ae1baf896929',
					applicationId: '3ce43660-fdfe-499a-9e47-61d4a6c0fa21',
					site: 'datadoghq.com',
					service: 'stash-web',
					version: window.Stash.version,
					env: window.Stash.env,
					sessionSampleRate: 100,
					sessionReplaySampleRate: 50,
					trackUserInteractions: true,
					trackFrustrations: true,
					trackInteractions: true,
					trackResources: true,
					trackLongTasks: true,
					silentMultipleInit: true,
					defaultPrivacyLevel: 'mask',
					allowedTracingUrls: [/https:\/\/.*\.stsh\.io/],
					beforeSend: (event) => {
						// Don't send warnings
						if (event.error?.message?.includes('Warning')) {
							return false;
						}
					},
				};
				datadogRum.init(config);
				datadogRum.startSessionReplayRecording();
				break;
			case AUTH_SESSION_START:
				datadogRum.setUser({ id: action.uuid });
				break;
			case LOG_OUT:
				datadogRum.setUser({ id: null });
				break;
			default:
		}
	}

	return next(action);
};

export default middleware;
